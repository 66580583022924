import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import Header from '../../../Components/SideNav';
import firebaseHelper from '../../../Helpers/Firebase';

class VerContacto extends Component{

    componentDidUpdate = () => {
        console.log(this.props.match.params.id);
    }

    atender = e =>{
        const { history } = this.props;
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        var editar = {
            captcha: false,
        }


        //actualizacion en la base de datos
        firebaseHelper.editar(this.props.mensaje.id, 'mensaje', firestore, editar, `/admin/contactos`, history);

    }

    render(){
        if (!this.props.mensaje) {
            return(
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return(
                <Fragment>
                        <Header mensaje="INFORMACION CONTACTO" />
                        <div className="container" >
                            <form onSubmit={this.atender}>
                                <p className='titulo_2'>
                                    {this.props.mensaje.nombre}
                                </p>
                                <p className='titulo_1'>
                                    E-mail: 
                                    <a href={"mailto:" + this.props.mensaje.email + "?Subject=Tour%20Di%20Viaggio.&body=%C2%A1Hola%20buen%20d%C3%ADa!"} target='blanck'>  {this.props.mensaje.email}</a>
                                </p>
                                <p>
                                    Asunto:
                                </p>
                                <p>
                                    {this.props.mensaje.mensaje}
                                </p>
                                <button  id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#002849 '}}>ATENDER</button>
                                <Link to={'/admin/contactos'} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#002849 '}}>REGRESAR</Link>
                                
                            </form>
                        </div>
                </Fragment>

            )
        }
    }
}

VerContacto.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'mensaje',
            doc: props.match.params.id
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        mensaje: ordered.mensaje && ordered.mensaje[0]
    }))
)(VerContacto)