import React, {Fragment, Component}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';


class NuevaCategoria extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }

    agregar = e => {
        e.preventDefault();
        const { history } = this.props;

        //extraer valores del state
        const nuevo = {...this.state};
    
        //extraer firestor de props
        const { firestore } = this.props;

        //guardado en la base de datos
        firebaseHelper.agregar('categoria', firestore, nuevo, '/admin/categorias', history);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render(){
        return (
            <Fragment>
                <Header mensaje="NUEVA CATEGORIA" />
                <div id="font" className="container">    
                    <div className="center" style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={this.agregar}>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                    <label for="nombre">Nombre</label>
                                </div>
                            </div>
                            <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#002849 '}}>AGREGAR</button>
                            <Link to={'/admin/categorias'} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#002849 '}}>REGRESAR</Link>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

NuevaCategoria.protoType = {
    firestor : PropTypes.object.isRequired
}

export default firestoreConnect() (NuevaCategoria);