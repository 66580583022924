import React, {Fragment}  from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

import './index.css';

// *** componentes ***  //
// import NavBar from './Components/Header';
//import Redes from './Components/Redes';

// *** vistas ***  //
// import Index from './Views/Index';
// import Nosotros from './Views/Nosotros';
// import Servicios from './Views/Servicios';
// import Contacto from './Views/Contacto';
// import Catalogo from './Views/Catalogo';
// import Producto from './Views/Producto';
// import FormularioPago from './Views/FormularioPago';
// import Carrito from './Views/Carrito';


// *** administrador *** //
import login from './Views/administrador/Login';
//usuarios
import usuarios from './Views/administrador/Usuarios/Usuarios';
import nuevoUsuario from './Views/administrador/Usuarios/NuevoUsuario';
import editarUsuario from './Views/administrador/Usuarios/EditarUsuario';
//categorias
import categorias from './Views/administrador/Categorias/Categorias';
import nuevoCategoria from './Views/administrador/Categorias/NuevaCategoria';
import editarCategoria from './Views/administrador/Categorias/EditarCategoria';
//sub-categorias
import subcategorias from './Views/administrador/SubCategorias/subCategorias';
import nuevoSubCategoria from './Views/administrador/SubCategorias/nuevaSubCategoria';
import editarSubCategoria from './Views/administrador/SubCategorias/editarSubCategoria';

// Membresias
import Membresias from './Views/administrador/Membresias/Membresias'

// Contactos
import Contactos from './Views/administrador/Contactos/Contactos';
import VerContacto from './Views/administrador/Contactos/VerContacto';

//productos
import productos from './Views/administrador/Productos/Productos';
import nuevoProducto from './Views/administrador/Productos/NuevoProducto';
import editarProducto from './Views/administrador/Productos/EditarProducto';
//imagenes
import imagenesCategorias from './Views/administrador/Imagenes/Categorias';
import imagenesProductos from './Views/administrador/Imagenes/Imagenes';
import nuevaImagenProducto from './Views/administrador/Imagenes/NuevaImagen';
import editarImagenProducto from './Views/administrador/Imagenes/EditarImagen';
import GaleriaProductos from './Views/administrador/Imagenes/GaleriaProductos';
//ventas
import ventas from './Views/administrador/Ventas/Ventas/Ventas';


const App = () =>(
    <Provider store={store}>

        <BrowserRouter>
            <Fragment>
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <div>
                        
                    </div>
                    // <NavBar />
                )}
                {/* administrador */}
                <   Route exact path = "/" component = {login} />
                        {/* usuario */}
                        <Route  path = "/admin/usuarios" component = {usuarios} />
                        <Route  path = "/admin/nuevo-usuario" component = {nuevoUsuario} />
                        <Route  path = "/admin/editar-usuario/:id" component = {editarUsuario} />
                        {/* fin - usuario */}
                        {/* categorias */}
                        <Route  path = "/admin/categorias" component = {categorias} />
                        <Route  path = "/admin/nueva-categoria" component = {nuevoCategoria} />
                        <Route  path = "/admin/editar-categoria/:id" component = {editarCategoria} />
                        {/* fin - categorias */}
                        {/* productos */}
                        <Route  path = "/admin/productos/:id" component = {productos} />
                        <Route  path = "/admin/nuevo-producto/:id" component = {nuevoProducto} />
                        <Route  path = "/admin/editar-producto/:id" component = {editarProducto} />
                        {/* fin - productos */}
                        {/* Membresias */}
                        <Route  path = "/admin/membresias" component = {Membresias} />
                        {/* fin - Membresias */}

                        {/* Contactos */}
                        <Route  path = "/admin/contactos" component = {Contactos} />
                        <Route  path = "/admin/ver-contacto/:id" component = {VerContacto} />
                        {/* fin - Contactos */}

                        {/* Ventas */}
                        <Route  path = "/admin/ventas" component = {ventas} />
                        {/* fin - Ventas */}

                        {/* imagenes */}
                        <Route  path = "/admin/imagenes-categorias" component = {imagenesCategorias} />
                        <Route  path = "/admin/imagenes-productos/:id" component = {imagenesProductos} />
                        <Route  path = "/admin/nueva-imagen-producto/:id" component = {nuevaImagenProducto} />
                        <Route  path = "/admin/editar-imagen-producto/:id" component = {editarImagenProducto} />
                        <Route  path = "/admin/galeria-producto/:id" component = {GaleriaProductos} />
                        {/* fin - imagenes */}
                    {/* fin - administrador */}
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <div>
                        
                    </div>
                    // <Footer />
                )}
            </Fragment>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
