import React, {Fragment, Component}  from 'react';
import Header from '../../../Components/SideNav';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { firestoreConnect } from 'react-redux-firebase';

class Contactos extends Component{


    // componentDidUpdate = () => {
    //     console.log(this.props.mensaje);
    // }

    render(){
        if (!this.props.mensaje) {
            return(
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return(
                <Fragment>
                        <Header mensaje="CONTACTOS" />
                        <div className="container">
                            {this.props.mensaje.length === 0 ? (
                                <div className="center">
                                    <h2 id="font">No hay usuarios registrados</h2>
                                </div>
                            ) : (
                                <Table striped bordered hover >
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>E-mail</th>
                                            <th>Estado</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody id="font">
                                        {this.props.mensaje.map(dato => (
                                            <tr key={dato.id}>
                                                <td>{dato.nombre}</td>
                                                <td>{dato.email}</td>
                                                <td>
                                                    {dato.captcha === false ? (
                                                        <div style={{width:"25px", height:"25px", borderRadius:"5px", backgroundColor:"green"}} />
                                                    ) : (
                                                        <div style={{width:"25px", height:"25px", borderRadius:"5px", backgroundColor:"red"}} />
                                                    )}          
                                                </td>
                                                <td>
                                                    <a style={{marginLeft:'14%'}} href={`/admin/ver-contacto/${dato.id}`}><i class="material-icons" style={{color:'#002849 '}}>remove_red_eye</i></a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                </Fragment>
            )
        }
    }
}

Contactos.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'mensaje'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        mensaje: ordered.mensaje
    }))

)(Contactos)