import React, {Fragment, Component}  from 'react';
import Header from '../../../Components/SideNav';
import PropTypes from 'prop-types';
import { firestoreConnect } from 'react-redux-firebase';
import firebaseHelper from '../../../Helpers/Firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import M from 'materialize-css';

class Membresias extends Component{

    constructor(props){
        super(props)
        this.state = {
            basica: '',
            internacional: '',
            plus: ''
        }
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        if (this.state.basica === '') {
            this.setState({
                basica: this.props.membresias.basica,
                internacional: this.props.membresias.internacional,
                plus: this.props.membresias.plus,
            });
        }
    }

    editar = e => {
        const { history } = this.props;
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        var editar = {
            basica: this.state.basica,
            internacional: this.state.internacional,
            plus: this.state.plus,
        }


        //actualizacion en la base de datos
        firebaseHelper.editar('eVYqkU67OROroY4eHsJy', 'membresias', firestore, editar, `/admin/membresias`, history);


    }

    render(){
        if (!this.props) {
            return(
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <Fragment>
                    <Header mensaje="EDITAR MEMBRESIAS" />
                    <div id="font-text" className="container center" style={{marginTop:'0px', marginBottom:'100px'}}>
                        <form onSubmit={this.editar}>
                            <div  style={{margin:'0 15%'}}>
                                <div class="file-field input-field">
                                    <h2>
                                        Básica
                                    </h2>
                                    <input required onChange={this.leerDato} defaultValue={this.state.basica} name='basica' id='basica' type='number' className='validate '/>
                                    <label for="">Precio</label>
                                </div>
                                <div class="file-field input-field">
                                    <h2>
                                        Plus
                                    </h2>
                                    <input required onChange={this.leerDato} defaultValue={this.state.plus} name='plus' id='plus' type='number' className='validate '/>
                                    <label for="">Precio</label>
                                </div>
                                <div class="file-field input-field">
                                    <h2>
                                        Internacional
                                    </h2>
                                    <input required onChange={this.leerDato} defaultValue={this.state.internacional} name='internacional' id='internacional' type='number' className='validate'/>
                                    <label for="">Precio</label>
                                </div>
                                <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#002849 '}}>EDITAR</button>
                            </div>

                        </form>
                    </div>
                </Fragment>
            )
        }
    }
}

Membresias.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'membresias',
            doc: 'eVYqkU67OROroY4eHsJy'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        membresias: ordered.membresias && ordered.membresias[0]
    }))

)(Membresias)