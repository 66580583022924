import React, {Fragment, Component}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import firebaseHelper from '../../../Helpers/Firebase';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';

class NuevoProducto extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            lugar: '',
            urlImagen: '',
            informacion: '',
            visa: '',
            ciudad: '',
            lenguaje: '',
            moneda:'',
            precio:'',
            categoria: this.props.match.params.id,
            uploadValue: 0
        }
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }
    
    
    agregar = e => {
        e.preventDefault();

        //extraer datos de props
        const { firestore } = this.props;

        var nuevo = {
            categoria: this.state.categoria,
            ciudad: this.state.ciudad,
            informacion:this.state.informacion,
            lugar:this.state.lugar,
            urlImagen:"",
            precio:this.state.precio,
            moneda:this.state.moneda,
            lenguaje:this.state.lenguaje,
            visa:this.state.visa
        }
        

        firestore.add({ 
            collection: "producto" 
        }, nuevo).then((doc) => {
            this.agregarImagen(doc.id);
        });
    }

    agregarImagen = (id) => {
        //extraer datos de props
        const { firestore } = this.props;
        const { history } = this.props;
        const { firebase } = this.props;

        var file1 = this.state.file1;
        const storageRef = firebase.storage().ref(`/images/productos/${id}/${id}`);
        const task = storageRef.put(file1);

        task.on('state_changed', snapshot => {
            let percentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 500;
            this.setState({
                uploadValue: percentaje
            })
        }, error => {
            Swal.fire(
                'Error',
                'Error al subir el archivo',
                'error'
                )
            }, () => {
            task.snapshot.ref.getDownloadURL().then((url) => {
                this.setState({
                    uploadValue: 2000,
                }, () => {
                    //guardado en la base de datos
                    firestore.update({
                        collection: "producto",
                        doc: id
                    }, {urlImagen: url} ).then(() => {
                        Swal.fire(
                            'Inserción exitosa!',
                            'Campo agregado correctamente',
                            'success'
                        )
                        //redireccion
                        history.push(`/admin/productos/${this.props.match.params.id}`)
                    });
                });
            })
        });
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    leerArchivo = e =>{
        e.preventDefault();

        // convertir imagen en base64
        let fileReader = new FileReader();

        fileReader.addEventListener('load', () => {
            this.setState({
                base64: fileReader.result,
            });
        });
        
        this.setState({
            file1: e.target.files[0]
        });
        fileReader.readAsDataURL(e.target.files[0]);
        // fin de convertir imagen a base64
    }

    leerInformacion = e => {
        this.setState({
            informacion: e.getData()
        });
    }

    render(){
        if (!this.props.categoria) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
        else{
            return (
                <Fragment>
                    <Header mensaje={"NUEVO LUGAR EN " + this.props.categoria.nombre.toUpperCase()  } />
                    <div id="font" className="container">    
                        <div className="center" style={{padding: '30px 0 30px 0'}}>
                            <div className='progress'>
                                <div className='determinate' style={{width:this.state.uploadValue + "%"}}></div>
                            </div>
                            <form onSubmit={this.agregar}>
                                <div  style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <img src={this.state.base64} id="imgSalida" alt="Imagen subida" style={{width:"100%"}} />
                                        <div class="file-field input-field">
                                            <div class="btn" onChange={this.leerArchivo}>
                                                <span>FILE</span>
                                                <input type="file" accept="image/*" name="urlImagen" id="urlImagen"/>
                                            </div>
                                            <div class="file-path-wrapper">
                                                <input accept="image/*" onChange={this.leerArchivo} class="file-path validate" type="text" placeholder='Seleccione una foto o arrastre una foto'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="lugar" id="lugar" type="text" class="validate" />
                                        <label for="lugar">Lugar</label>
                                    </div>
                                </div>

                                <h3>
                                    Información del Lugar
                                </h3>

                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            onChange={ ( event, editor ) => {
                                                this.leerInformacion(editor);
                                            }}
                                        />
                                    </div>
                                </div>
                                <h3>
                                    Información basica
                                </h3>

                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="visa" id="visa" type="text" step=".01" class="validate" />
                                        <label for="visa">Visa</label>
                                    </div>
                                </div>
                                <div style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="ciudad" id="ciudad" type="text" class="validate" />
                                        <label for="ciudad">Ciudad</label>
                                    </div>
                                </div>
                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="lenguaje" id="lenguaje" type="text" class="validate" />
                                        <label for="lenguaje">Lenguaje</label>
                                    </div>
                                </div>
                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="moneda" id="moneda" type="text" class="validate" />
                                        <label for="moneda">Moneda</label>
                                    </div>
                                </div>
                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} name="precio" id="precio" type="text" class="validate" />
                                        <label for="precio">Precio</label>
                                    </div>
                                </div>
    
                                <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#002849 ', marginTop:'25px'}}>AGREGAR</button>
                                <Link to={`/admin/productos/${this.props.match.params.id}`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#002849 ', marginTop:'25px'}}>REGRESAR</Link>
                            </form>
                        </div>
                    </div>
                </Fragment>
            );

        }
    }
}

NuevoProducto.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'categoria',
            doc: props.match.params.id
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categoria: ordered.categoria && ordered.categoria[0]
    }))
)(NuevoProducto);