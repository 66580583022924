import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';


class Categorias extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }

    busqueda = (id) => {
        const { firestore } = this.props;

        function consulta1() {
            return new Promise(resolve => {
                firestore.collection("producto")
                .where("categoria", "==", id)
                .get()
                .then(function(querySnapshot) {
                    if (querySnapshot.docs.length === 0) {
                        resolve(1);
                    } else {
                        querySnapshot.forEach(function (doc) {
                            //console.log(doc.id, ' => ', doc.data());
                            resolve(3);
                        });
                    }
                }).catch(function(error) {
                    //console.log("Error getting document:", error);
                    resolve(2);
                });
            })
        }

        async function guardarState1() {
            const acceso =  consulta1();
            return acceso;
        }

        guardarState1().then(resultado => {
            if (resultado === 1) {
                this.eliminar(id);
            } else if (resultado === 2) {
                Swal.fire(
                    'Error!',
                    'Opss, Error al eliminar la variante',
                    'error'
                )
            } else if (resultado === 3) {
                Swal.fire(
                    'Error!',
                    'Opss, No se puede eliminar la variante! <br/> La variante cuenta con productos <br/> Primero elimine los productos',
                    'error'
                )
            }
        })
    }

    eliminar = (id) => {
        const { firestore } = this.props;

        firebaseHelper.eliminar(id, 'categoria', firestore);
    }


    render(){
        if (!this.props.categoria) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="PAISES" />

                    <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                        <Link to={'/admin/nueva-categoria'} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', background:'#002849 '}}>AGREGAR PAIS</Link>
                    </div>

                    <div className="container" style={{padding: '0 0 100px 0'}}>
                        {this.props.categoria.length === '0' ? (
                            <span></span>
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {this.props.categoria.map(dato => (
                                        <tr key={dato.id}>
                                            <td>{dato.nombre}</td>
                                            <td>
                                                <Link style={{marginLeft:'14%'}} to={`/admin/productos/${dato.id}`}><i class="material-icons" style={{color:'#002849 '}}>remove_red_eye</i></Link>
                                                <Link style={{marginLeft:'14%'}} to={`/admin/editar-categoria/${dato.id}`}><i class="material-icons" style={{color:'#002849 '}}>create</i></Link>
                                                <div onClick={ () => this.busqueda(dato.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#002849 '}}>delete</i></div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Categorias.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'categoria'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categoria: ordered.categoria
    }))
)(Categorias);