import React, {Fragment, Component}  from 'react';
import conekta from '../../../../Helpers/Conekta' 
import Header from '../../../../Components/SideNav'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';


class Ventas extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            rango: [
                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), 
                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)
            ], 
            ventas: "",
            total: ""
        }
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    buscar = async () => {
        let fecha_inicial = Math.ceil(this.state.rango[0].getTime() / 1000);
        let fecha_final = Math.ceil(this.state.rango[1].getTime() / 1000);
        this.setState({
            ventas: false
        });
        console.log(fecha_inicial, fecha_final);
        var res = await conekta.obtenerVentas(fecha_inicial, fecha_final);
        if (res.type) {
            this.setState({
                ventas: res.res.data,
                total: res.total,
                total_cargos: res.total_cargos
            });
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Error al buscar ventas',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        }
    }


    render(){
        return (
            <Fragment>
                <Header mensaje="VENTAS" />

                <div className="container">
                    <DateRangePickerComponent placeholder="Selecciona el rango de fechas"
                        name="rango"
                        onChange={this.leerDato}
                        startDate={new Date(this.state.rango[0])}
                        endDate={new Date(this.state.rango[1])}
                        minDays={2}
                        maxDays={31}
                        format="dd-MMM-yyyy"
                    ></DateRangePickerComponent>
                    <button id="font-button" class="btn" onClick={this.buscar} style={{padding:'0 50px 0 50px', margin:"50px", background:'#002849'}}>BUSCAR</button>
                </div>

                <div className="container center" id="datos_ventas">
                    {this.state.ventas === "" ? (
                        <h2>Selecciona un rango de fechas</h2>
                    ) : (
                        !this.state.ventas ? (
                            <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                                <div class="preloader-wrapper big active">
                                    <div class="spinner-layer spinner-red darken-4-only">
                                        <div class="circle-clipper left">
                                            <div class="circle"></div>
                                        </div>
                                        <div class="gap-patch">
                                            <div class="circle"></div>
                                        </div>
                                        <div class="circle-clipper right">
                                            <div class="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            this.state.ventas.length === 0 ? (
                                <h2>Sin ventas en el rango seleccionado</h2>
                            ) : (
                                <div>
                                    <Table striped bordered hover style={{marginBottom:'50px'}}>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Estado de pago</th>
                                                <th>Tipo de cargo</th>
                                                <th>Cliente</th>
                                                <th>Monto</th>
                                                <th>Cargos</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody id="font">
                                            {this.state.ventas.map(dato => (
                                                <tr key={dato.id}>
                                                    <td>{
                                                        new Date(dato.created_at * 1000).getDate() + "/" +
                                                        new Date(dato.created_at * 1000).getMonth() + "/" +
                                                        new Date(dato.created_at * 1000).getFullYear() + " " +
                                                        new Date(dato.created_at * 1000).getHours() + ":" + 
                                                        new Date(dato.created_at * 1000).getMinutes()
                                                    }</td>
                                                    <td>
                                                        {
                                                            dato.last_payment_info === undefined ? (
                                                                <p style={{color:"grey"}}>Sin cargo</p>
                                                            ) : (
                                                                dato.last_payment_info.status === "paid" ? (
                                                                    <p style={{color:"green"}}>Pagado</p>
                                                                ) : (
                                                                    <p style={{color:"red"}}>Declinado</p>
                                                                )
                                                            )
                                                            
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            dato.last_payment_info === undefined ? (
                                                                <p style={{color:"grey"}}>Sin cargo</p>
                                                            ) : (
                                                                dato.last_payment_info.payment_method === "CreditCardPayment" ? (
                                                                    <p>Tarjeta</p>
                                                                ) : (
                                                                    <p>Otro</p>
                                                                )
                                                            )
                                                            
                                                        }
                                                    </td>
                                                    <td>{dato.customer_info.name}</td>
                                                    <td>${parseFloat(dato.amount / 100).toFixed(2)}</td>
                                                    <td>{
                                                        dato.charges === undefined ? (
                                                            <p style={{color:"grey"}}>Sin cargo</p>
                                                        ) : (
                                                            "$" + parseFloat(dato.charges.data[0].fee / 100).toFixed(2)
                                                        )
                                                    }</td>
                                                    <td>
                                                        <a style={{marginLeft:'14%'}} target="blank" href={`/admin/venta/${dato.id}`}><i class="far fa-eye" style={{color:'#813A76 '}}></i></a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <div style={{textAlign:"left"}}>
                                        <h2>Total: $ {parseFloat(this.state.total).toFixed(2)}</h2>
                                        <h2>Cargos totales: $ {this.state.total_cargos.toFixed(2)}</h2>
                                        <h2>Total con cargos: $ {parseFloat(this.state.total - this.state.total_cargos).toFixed(2)}</h2>
                                    </div>
                                </div>
                            )
                        )
                    )}
                </div>
            </Fragment>
        );
    }
}

export default Ventas;