import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import Swal from 'sweetalert2';
import firebaseHelper from '../../../Helpers/Firebase';


class GaleriaProductos extends Component{
    constructor(props){
        super(props)
        this.state = {
            nombre: '',
            urlImagen:'',
            imagenesArray: "",
            uploadValue: 0
        }
    }

    obtenrUrl = (data, index, array) => {
        let { firebase } = this.props;
        let imagenesArray = array;

        // Get a reference to the storage service, which is used to create references in your storage bucket
        var storage = firebase.storage();

        // Create a storage reference from our storage service
        var storageRef = storage.ref();

        storageRef.child(data[index].fullPath).getDownloadURL()
        .then((url) => {                
            imagenesArray.push({nombre: data[index].name, url});
            if (index + 1 === data.length) {
                this.setState({
                    imagenesArray
                });
                var elems = document.querySelectorAll('.materialboxed');
                M.Materialbox.init(elems);
            } else {
                index = index + 1;
                this.obtenrUrl(data, index, imagenesArray);
            }
        })
        .catch((error) => {
            // Handle any errors
        });
    }
    
    componentDidUpdate = () => {
        // console.log(this.props.producto)
        if (this.props.producto && this.state.imagenesArray === "") {
            let { firebase } = this.props;

            // Get a reference to the storage service, which is used to create references in your storage bucket
            var storage = firebase.storage();

            // Create a storage reference from our storage service
            var storageRef = storage.ref();

            // Create a reference under which you want to list
            var listRef = storageRef.child(`/images/productos/${this.props.producto.id}/galeria`);

            // Find all the prefixes and items.
            listRef.listAll()
            .then((res) => {
                this.obtenrUrl(res.items, 0, []);
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });
        }
    }

    eliminar = (urlImagen) => {
        console.log("eliminar");
        const { firestore } = this.props;
        const { firebase } = this.props;

        var storage = firebase.storage();
        var storageRefEliminar = storage.refFromURL(urlImagen);

        storageRefEliminar.delete().then(() => {
            Swal.fire({
                title: 'Eliminación exitosa',
                text:'El campo fue eliminado exitosamente',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon:'success'
            }).then(res => {
                if (res.isConfirmed || res.isDismissed) {
                    window.location.reload();
                }
            });
        }).catch(error => {
            //console.log("error => ", error);
            Swal.fire({
                title: 'Error',
                text: 'Error al aliminar archivo 1',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon: 'error'
            })
        });
        
    }

    agregarImagen = (e) => {
        e.preventDefault();

        //extraer datos de props
        const { firestore } = this.props;
        const { history } = this.props;
        const { firebase } = this.props;

        let id = this.props.match.params.id;

        var file1 = this.state.file1;
        const storageRef = firebase.storage().ref(`/images/productos/${id}/galeria/${this.state.nombre}`);
        const task = storageRef.put(file1);

        task.on('state_changed', snapshot => {
            let percentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 500;
            this.setState({
                uploadValue: percentaje
            })
        }, error => {
            Swal.fire(
                'Error',
                'Error al subir el archivo',
                'error'
                )
            }, () => {
            task.snapshot.ref.getDownloadURL().then((url) => {
                console.log(url);
                this.setState({
                    uploadValue: 2000,
                }, () => {
                    console.log("entor");
                    // guardado en la base de datos
                    firestore.update({
                        collection: "producto",
                        doc: id
                    }, {urlImagen: url} ).then(() => {
                        Swal.fire(
                            'Inserción exitosa!',
                            'Campo agregado correctamente',
                            'success'
                        ).then(res => {
                            if (res.isConfirmed || res.isDismissed) {
                                window.location.reload();
                            }
                        });
                    });
                });
            })
        });
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    leerArchivo = e =>{
        e.preventDefault();

        // convertir imagen en base64
        let fileReader = new FileReader();

        fileReader.addEventListener('load', () => {
            this.setState({
                base64: fileReader.result,
            });
        });
        
        this.setState({
            file1: e.target.files[0]
        });
        fileReader.readAsDataURL(e.target.files[0]);
        // fin de convertir imagen a base64
    }
    

    render(){
        if (!this.props.producto) {
            return(
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return(
                <Fragment>
                    <Header mensaje={"AGREGAR IMAGEN A GALERIA " + this.props.producto.lugar.toUpperCase()  } />
                    <div className='container' style={{marginTop:"50px"}}>
                        <div class="progress">
                            <div class="determinate" style={{width:this.state.uploadValue + "%"}}></div>
                        </div>
                        <div id="preloader" style={{display: "flex", justifyContent: "center", marginBottom: "30px", justifyItems: "center"}}></div>
                        <div className="row">
                            <div className="col s4">
                                <form onSubmit={this.agregarImagen}>
                                    <div class="input-field">
                                        <input required="" onChange={this.leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                        <label for="nombre">Nombre</label>
                                    </div>  
                                    <div onChange={this.leerArchivo} className="file-field input-field">
                                        <div className="btn">
                                            <span>File</span>
                                            <input name="file" type="file"/>
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" onChange={this.leerArchivo} type="text" placeholder="Seleccione una foto o arrastre una foto"/>
                                        </div>
                                    </div>
                                    <div className=" btn-outline-light center" style={{padding: '30px 0px', display:"flex", justifyContent:"center", flexDirection:"column"}}>
                                        <button type='submit' id="font" class="btn" style={{padding:'0 50px 0 50px', background:'#002849 '}}>AGREGAR</button>
                                        <Link to={`/admin/imagenes-productos/${this.props.producto.categoria}`} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginTop:'20px', background:'#002849 '}}>REGRESAR</Link>
                                    </div>
                                </form>
                            </div>
                            <div className="col s8">
                                <img alt="imagen subida" id="imgSalida" width="100%" src={this.state.base64}></img>
                            </div>
                        </div>
                        {this.state.imagenesArray === undefined ? (
                            window.location.reload()
                            ) : (
                                this.state.imagenesArray === "" ? (
                                    <h1>cargando...</h1>
                                ) : (
                                    this.state.imagenesArray.length === 0 ? (
                                        <div className="center">
                                            <h2 id="font">No hay imagenes registradas</h2>
                                        </div>
                                    ) : (
                                        <div>
                                            <Table striped bordered hover style={{marginBottom:'50px'}}>
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Imagen</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="font">
                                                    {this.state.imagenesArray.map(dato => (
                                                        dato.id != "" ? (
                                                            <tr key={dato.id}>
                                                                <td>{dato.nombre}</td>
                                                                <td>
                                                                    <div className='material-placeholder'>
                                                                        <img src={dato.url} alt="" className='materialboxed' style={{width:"100px"}}/>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div onClick={ () => this.eliminar(dato.url)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#002849 '}}>delete</i></div>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <span></span>
                                                        )
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )
                                )
                            )}
                    </div>
                </Fragment>
            )
        }

    }
}

GaleriaProductos.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'producto',
            doc: props.match.params.id
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        producto: ordered.producto && ordered.producto[0]
    }))
)(GaleriaProductos);