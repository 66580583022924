import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import firebaseHelper from '../../../Helpers/Firebase';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class EditarProducto extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            lugar: '',
            urlImagen: '',
            informacion: '',
            visa: '',
            ciudad: '',
            lenguaje: '',
            moneda:'',
            precio:'',
            categoria: this.props.match.params.id
        }
    }

    editar = e => {
        const { history } = this.props;
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        var editar = {
            lugar: this.state.lugar,
            informacion: this.state.informacion,
            visa: this.state.visa,
            ciudad: this.state.ciudad,
            lenguaje: this.state.lenguaje,
            moneda: this.state.moneda,
            precio: this.state.precio,
            urlImagen: this.state.urlImagen,
        }


        //actualizacion en la base de datos
        firebaseHelper.editar(this.props.producto.id, 'producto', firestore, editar, `/admin/productos/${this.props.producto.categoria}`, history);

    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    leerArchivo = e =>{
        e.preventDefault();

        // convertir imagen en base64
        let fileReader = new FileReader();

        fileReader.addEventListener('load', () => {
            this.setState({
                base64: fileReader.result,
            });
        });
        
        this.setState({
            file1: e.target.files[0]
        });
        fileReader.readAsDataURL(e.target.files[0]);
        // fin de convertir imagen a base64
    }

    leerInformacion = e => {
        this.setState({
            informacion: e.getData()
        });
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        if (this.state.lugar === '') {
            this.setState({
                lugar: this.props.producto.lugar,
                informacion: this.props.producto.informacion,
                visa: this.props.producto.visa,
                ciudad: this.props.producto.ciudad,
                lenguaje: this.props.producto.lenguaje,
                moneda: this.props.producto.moneda,
                precio: this.props.producto.precio,
                urlImagen: this.props.producto.urlImagen
            });
        }
        console.log(this.state)
    }

    render(){
        if (!this.props.producto) {
            return(
                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="EDITAR CATEGORIA" />
                    <div id="font-text" className="container center" style={{marginTop:'0px', marginBottom:'100px'}}>
                            <form onSubmit={this.editar}>
                                <div  style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <img src={this.state.base64} id="imgSalida" alt="Imagen subida" style={{width:"100%"}} />
                                        <div class="file-field input-field">
                                            <div class="btn" onChange={this.leerArchivo}>
                                                <span>FILE</span>
                                                <input type="file" accept="image/*" name="urlImagen" id="urlImagen"/>
                                            </div>
                                            <div class="file-path-wrapper">
                                                <input accept="image/*" onChange={this.leerArchivo} class="file-path validate" type="text" placeholder='Seleccione una foto o arrastre una foto'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} defaultValue={this.state.lugar} name="lugar" id="lugar" type="text" class="validate" />
                                        <label for="lugar" className='active'>Lugar</label>
                                    </div>
                                </div>

                                <h3>
                                    Información del Lugar
                                </h3>

                                <div style={{margin:'5% 15%'}}>
                                    {this.state.informacion && (
                                        <div class="input-field col s12">
                                            <CKEditor
                                                editor={ ClassicEditor }
                                                data= {String(this.state.informacion)}
                                                onChange={ ( event, editor ) => {
                                                    this.leerInformacion(editor);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <h3>
                                    Información basica
                                </h3>

                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} defaultValue={this.state.visa} name="visa" id="visa" type="text" step=".01" class="validate" />
                                        <label for="visa" className='active'>Visa</label>
                                    </div>
                                </div>
                                <div style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} defaultValue={this.state.ciudad} name="ciudad" id="ciudad" type="text" class="validate" />
                                        <label for="ciudad" className='active'>Ciudad</label>
                                    </div>
                                </div>
                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} defaultValue={this.state.lenguaje} name="lenguaje" id="lenguaje" type="text" class="validate" />
                                        <label for="lenguaje" className='active'>Lenguaje</label>
                                    </div>
                                </div>
                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} defaultValue={this.state.moneda} name="moneda" id="moneda" type="text" class="validate" />
                                        <label for="moneda" className='active'>Moneda</label>
                                    </div>
                                </div>
                                <div style={{margin:'5% 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} defaultValue={this.state.precio} name="precio" id="precio" type="text" class="validate" />
                                        <label for="precio" className='active'>Precio</label>
                                    </div>
                                </div>
    
    
                                <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#002849 '}}>EDITAR</button>
                                <Link to={`/admin/productos/${this.props.producto.categoria}`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#002849 '}}>REGRESAR</Link>
                            </form>
                    </div>
                </Fragment>
            );
        }
    }
}

EditarProducto.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'producto',
            doc: props.match.params.id
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        producto: ordered.producto && ordered.producto[0]
    }))
)(EditarProducto)